var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "progress-banner", attrs: { "no-body": "" } },
    [
      _c(
        "b-card-body",
        {
          staticClass:
            "justify-content-between d-flex flex-row align-items-center",
        },
        [
          _c("div", [
            _c("i", {
              class: `${_vm.icon} mr-2 text-white align-text-bottom d-inline-block`,
            }),
            _vm._v(" "),
            _c("div", [
              _c("p", { staticClass: "lead text-white" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-small text-white" }, [
                _vm._v(_vm._s(_vm.detail)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "progress-bar-circle progress-bar-banner position-relative",
            },
            [
              _c(
                "radial-progress-bar",
                {
                  attrs: {
                    diameter: _vm.diameter,
                    strokeWidth: _vm.strokeWidth,
                    "completed-steps": _vm.percent,
                    "total-steps": 100,
                  },
                },
                [_vm._v(_vm._s(_vm.progressText))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }