var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "router-link",
        { staticClass: "link-icon", attrs: { tag: "a", to: _vm.detailPath } },
        [_c("i", { staticClass: "simple-icon-compass" })]
      ),
      _vm._v(" "),
      _vm.data
        ? _c(
            "vue-perfect-scrollbar",
            {
              staticClass: "scroll dashboard-list-with-user",
              attrs: {
                settings: { suppressScrollX: true, wheelPropagation: false },
              },
            },
            _vm._l(_vm.data, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "d-flex flex-row mb-3 pb-3 border-bottom",
                },
                [
                  _c(
                    "router-link",
                    { attrs: { tag: "a", to: _vm.detailPath } },
                    [
                      _c("img", {
                        staticClass:
                          "img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall",
                        attrs: { src: item.thumb, alt: item.title },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pl-3 pr-2" },
                    [
                      _c(
                        "router-link",
                        { attrs: { tag: "a", to: _vm.detailPath } },
                        [
                          _c("p", { staticClass: "font-weight-medium mb-0" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "text-muted mb-0 text-small" },
                            [
                              _vm._v(
                                _vm._s(
                                  item.details +
                                    " | " +
                                    item.day_name +
                                    " | " +
                                    item.week_name
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }