var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "d-flex mb-4 dashboard-header" }, [
        _c("h2", { staticClass: "align-self-center pb-0 mb-0" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("menu.vmb-menu.performance")) + "\n    "
          ),
        ]),
        _vm._v(" "),
        _vm.currentUser
          ? _c("span", {
              staticClass: "avatar avatar-sm mx-3 align-self-center",
              style: {
                backgroundImage: "url(" + _vm.currentUser.teamImg + ")",
              },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-colxx",
            {
              staticClass: "mb-4",
              attrs: { xl: "4", lg: "12", sm: "12", md: "12" },
            },
            [
              _c(
                "b-card",
                {
                  staticClass:
                    "dashboard-filled-line-chart dashboard-link-list",
                  attrs: { "no-body": "" },
                },
                [
                  _c("b-container", { staticClass: "chart-title" }, [
                    _c("div", { staticClass: "float-left float-none-xs" }, [
                      _c("div", { staticClass: "d-inline-block" }, [
                        _c("h5", { staticClass: "d-inline" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("pages.vmb.dashboard.volume")) +
                              "\n              "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chart card-body pt-0 pl-3 pr-3 pb-0" },
                    [
                      _c("scale-loader", {
                        attrs: {
                          loading: !_vm.vmbCharts,
                          color: _vm.loader.color,
                        },
                      }),
                      _vm._v(" "),
                      _vm.vmbCharts
                        ? _c("bar-chart", {
                            attrs: {
                              "chart-data": _vm.claimsProcessedChart,
                              options: _vm.claimsProcessedChartOptions,
                              height: 250,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-colxx",
            {
              staticClass: "mb-4",
              attrs: { xl: "4", lg: "12", sm: "12", md: "12" },
            },
            [
              _c(
                "b-card",
                {
                  staticClass:
                    "dashboard-filled-line-chart dashboard-link-list",
                  attrs: { "no-body": "" },
                },
                [
                  _c("b-container", { staticClass: "chart-title" }, [
                    _c("div", { staticClass: "float-left float-none-xs" }, [
                      _c("div", { staticClass: "d-inline-block" }, [
                        _c("h5", { staticClass: "d-inline" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("pages.vmb.dashboard.accuracy")) +
                              "\n              "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chart card-body pt-0 pl-3 pr-3 pb-0" },
                    [
                      _c("scale-loader", {
                        attrs: {
                          loading: !_vm.vmbCharts,
                          color: _vm.loader.color,
                        },
                      }),
                      _vm._v(" "),
                      _vm.vmbCharts
                        ? _c("bar-chart", {
                            attrs: {
                              "chart-data": _vm.accuracyChart,
                              options: _vm.accuracyChartOptions,
                              height: 250,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-colxx",
            {
              staticClass: "mb-4",
              attrs: { xl: "4", lg: "12", sm: "12", md: "12" },
            },
            [
              _c(
                "b-card",
                {
                  staticClass:
                    "dashboard-filled-line-chart dashboard-link-list",
                  attrs: { "no-body": "" },
                },
                [
                  _c("b-container", { staticClass: "chart-title" }, [
                    _c("div", { staticClass: "float-left float-none-xs" }, [
                      _c("div", { staticClass: "d-inline-block" }, [
                        _c("h5", { staticClass: "d-inline" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t("pages.vmb.dashboard.profitability")
                              ) +
                              "\n              "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chart card-body pt-0 pl-3 pr-3 pb-0" },
                    [
                      _c("scale-loader", {
                        attrs: {
                          loading: !_vm.vmbCharts,
                          color: _vm.loader.color,
                        },
                      }),
                      _vm._v(" "),
                      _vm.vmbCharts
                        ? _c("bar-chart", {
                            attrs: {
                              "chart-data": _vm.profitabilityChart,
                              options: _vm.profitabilityChartOptions,
                              height: 250,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-colxx",
            { attrs: { xxs: "12" } },
            [
              _c(
                "b-card",
                {
                  staticClass: "mb-4 stats-table",
                  attrs: { title: _vm.$t("pages.vmb.performance.weekly-data") },
                },
                [
                  _c("scale-loader", {
                    attrs: {
                      loading: !_vm.weeklyData,
                      color: _vm.loader.color,
                    },
                  }),
                  _vm._v(" "),
                  _vm.weeklyData
                    ? _c("b-table", {
                        attrs: {
                          responsive: "",
                          "sticky-header": "",
                          items: _vm.weeklyData,
                          fields: _vm.weeklyDataFields,
                          "table-class": "text-nowrap",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-colxx",
            { attrs: { xxs: "12" } },
            [
              _c(
                "b-card",
                {
                  staticClass: "mb-4 stats-table",
                  attrs: { title: _vm.$t("pages.vmb.performance.yesterday") },
                },
                [
                  _c("scale-loader", {
                    attrs: {
                      loading: !_vm.yesterdayData,
                      color: _vm.loader.color,
                    },
                  }),
                  _vm._v(" "),
                  _vm.yesterdayData
                    ? _c("b-table", {
                        attrs: {
                          responsive: "",
                          "sticky-header": "",
                          items: _vm.yesterdayData,
                          fields: _vm.yesterdayDataFields,
                          "table-class": "text-nowrap",
                          "tbody-tr-class": _vm.rowClass,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }