var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "people" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-colxx",
            { staticClass: "pt-4 mb-5 dashboard-header", attrs: { xxs: "12" } },
            [
              _c("h1", { staticClass: "align-middle pb-0 mb-0" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("menu.vmb-menu.people")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _vm.currentUser
                ? _c("span", {
                    staticClass: "avatar avatar-sm ml-2 align-middle",
                    style: {
                      backgroundImage: "url(" + _vm.currentUser.teamImg + ")",
                    },
                  })
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-colxx",
            { staticClass: "mb-4", attrs: { lg: "12", md: "12", sm: "12" } },
            [
              _c("scale-loader", {
                attrs: { loading: !_vm.vmbData, color: _vm.loader.color },
              }),
              _vm._v(" "),
              _vm.vmbData
                ? _c(
                    "b-row",
                    _vm._l(
                      _vm.orderedItems(_vm.vmbData.people),
                      function (item) {
                        return _c(
                          "b-colxx",
                          {
                            key: item.id,
                            staticClass: "mb-4 text-center",
                            attrs: { xl: "3", lg: "4", md: "6", sm: "12" },
                          },
                          [
                            _c(
                              "b-card",
                              [
                                _c("h3", { staticClass: "mb-1 pb-1" }, [
                                  _vm._v(_vm._s(item.details)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "radial-progress-bar",
                                  {
                                    staticClass: "mb-2",
                                    attrs: {
                                      diameter: 200,
                                      strokeWidth: 20,
                                      "completed-steps": parseInt(item.title),
                                      "total-steps": 10,
                                      startColor: _vm.setChartColor(
                                        parseInt(item.title)
                                      ),
                                      stopColor: _vm.setChartColor(
                                        parseInt(item.title)
                                      ),
                                      animatedSpeed: "1000",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "mb-0 pb-0 score" },
                                      [_vm._v(_vm._s(item.title))]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }