<template>
  <div>
    <router-link tag="a" :to="detailPath" class="link-icon">
      <i class="simple-icon-compass" />
    </router-link>
    <vue-perfect-scrollbar
      v-if="data"
      class="scroll dashboard-list-with-user"
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
    >
      <div
        class="d-flex flex-row mb-3 pb-3 border-bottom"
        v-for="(item, index) in data"
        :key="index"
      >
        <router-link tag="a" :to="detailPath">
          <img
            :src="item.thumb"
            :alt="item.title"
            class="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
          />
        </router-link>
        <div class="pl-3 pr-2">
          <router-link tag="a" :to="detailPath">
            <p class="font-weight-medium mb-0">{{ item.title }}</p>
            <p
              class="text-muted mb-0 text-small"
            >{{ item.details + ' | ' + item.day_name + ' | ' + item.week_name }}</p>
          </router-link>
        </div>
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
export default {
  props: ["detailPath", "data"],
};
</script>

<style lang="scss" scoped>
.link-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1.75rem 1.75rem 0 0;
}

i {
  font-size: 1.2rem;
}
</style>