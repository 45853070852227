var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "survey-observation-details" }, [
    _c("div", { staticClass: "d-flex mb-4 dashboard-header" }, [
      _c("h2", { staticClass: "align-self-center pb-0 mb-0" }, [
        _vm._v(_vm._s(_vm.$route.meta.title)),
      ]),
      _vm._v(" "),
      _vm.currentUser
        ? _c("span", {
            staticClass: "avatar avatar-sm mx-3 align-self-center",
            style: {
              backgroundImage: "url(" + _vm.currentUser.teamImg + ")",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ml-auto align-self-center" },
        [
          _vm.vmbObservationData
            ? _c("switches", {
                staticClass: "mb-0",
                attrs: {
                  theme: "custom",
                  color: "primary vue-switcher-small text-center",
                  label: "All",
                },
                on: { input: _vm.onSwitch },
                model: {
                  value: _vm.showAll,
                  callback: function ($$v) {
                    _vm.showAll = $$v
                  },
                  expression: "showAll",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm.vmbObservationData
      ? _c(
          "div",
          [
            _c(
              "b-row",
              _vm._l(_vm.tableData, function (item, index) {
                return _c(
                  "b-col",
                  {
                    key: index,
                    staticClass: "mb-4",
                    attrs: { xl: "3", lg: "6", md: "12", sm: "12" },
                  },
                  [
                    _c(
                      "b-card",
                      { staticClass: "detail-card", attrs: { "no-body": "" } },
                      [
                        _c(
                          "b-card-body",
                          [
                            _c(
                              "div",
                              { staticClass: "action-toggle text-center" },
                              [
                                _c("toggle-button", {
                                  attrs: {
                                    value: _vm.getCurrentStatus(item.status),
                                    labels: {
                                      checked: "Open",
                                      unchecked: "Complete",
                                    },
                                    color: "#2d7281",
                                    width: 80,
                                    sync: true,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onCompleteSwitch($event, item)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-container",
                              [
                                _c(
                                  "b-row",
                                  {
                                    staticClass:
                                      "justify-content-center text-center",
                                  },
                                  [
                                    _c(
                                      "b-col",
                                      { staticClass: "col-12" },
                                      [
                                        _c("b-avatar", {
                                          attrs: {
                                            src: item.thumb,
                                            size: "3.5em",
                                            text: _vm.getNameInitials(
                                              item.details
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-col",
                                      { staticClass: "col-12 mt-3 p-0" },
                                      [
                                        _c("h6", { staticClass: "pb-0" }, [
                                          _vm._v(_vm._s(item.details)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "b-badge",
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              variant: "outline-danger",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(item.day_name) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-badge",
                                          {
                                            staticClass: "ml-1",
                                            attrs: {
                                              variant: "outline-primary",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(item.week_name) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "vue-perfect-scrollbar",
                                          {
                                            staticClass:
                                              "scroll dashboard-list-with-comments mt-3 p-0",
                                            attrs: {
                                              settings: {
                                                suppressScrollX: true,
                                                wheelPropagation: false,
                                              },
                                            },
                                          },
                                          [
                                            _c("h6", [
                                              _vm._v(_vm._s(item.title)),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "spinner" },
          [_c("ring-loader", { attrs: { color: _vm.loader.color } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }