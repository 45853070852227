<template>
  <div class="dashboards">
    <div class="d-flex mb-2">
      <h2 class="align-self-center pb-0 mb-0">
        {{ currentUser.team }} {{ $t('pages.vmb.dashboard.title') }}
      </h2>
      <p class="align-self-center p-0 m-0 mx-2">
        <b-badge
          v-if="openTeamWeek"
          variant="outline-info"
          class="animate__animated animate__fadeInDown"
        >
          {{ openTeamWeek.open_week_name }}
        </b-badge>
      </p>
      <p class="align-self-center p-0 m-0">
        <b-badge
          v-if="openTeamWeek"
          variant="outline-new"
          class="animate__animated animate__fadeInDown"
        >
          {{ openTeamWeek.open_day_name }}
        </b-badge>
      </p>
      <b-button
        v-if="openTeamWeek"
        class="ml-auto align-self-center btn-shadow"
        size="sm"
        variant="new"
        @click="onVmbClick"
      >
        {{ $t('pages.vmb.dashboard.update-vmb') }}
      </b-button>
    </div>
    <b-row>
      <b-colxx xl="3" lg="12" md="12" sm="12" class="mb-4">
        <b-card :title="$t('pages.vmb.dashboard.what-went-well')" class="dashboard-link-list">
          <scale-loader
            :loading="!vmbObservationData || loadingElement === 3"
            :color="loader.color"
          ></scale-loader>
          <to-do-items
            :header="$t('pages.vmb.dashboard.what-went-well')"
            v-if="vmbObservationData"
            :class="{ loadingElement: loadingElement === 3 }"
            :type="3"
            :data="orderedItems(vmbObservationData.what_went_well)"
            detail-path="/vmb/what-went-well"
            @clicked="onClickItem"
            @changed="onChangeItem"
          ></to-do-items>
        </b-card>
      </b-colxx>
      <b-colxx xl="6" lg="12" sm="12" md="12" class="mb-4">
        <b-card class="dashboard-filled-line-chart dashboard-link-list" no-body>
          <scale-loader :loading="!vmbCharts" :color="loader.color"></scale-loader>
          <div class="chart-title d-flex">
            <h5 class="d-inline align-self-center">{{ $t('pages.vmb.dashboard.volume') }}</h5>
            <router-link
              tag="a"
              to="/vmb/performance"
              v-if="vmbCharts"
              class="link-icon align-self-center ml-auto"
            >
              <i :class="navIcon" />
            </router-link>
          </div>
          <div class="chart card-body pt-0 pl-3 pr-3 pb-0">
            <bar-chart
              v-if="vmbCharts"
              :chart-data="claimsProcessedChart"
              :options="claimsProcessedChartOptions"
              :height="250"
            />
          </div>
        </b-card>
      </b-colxx>
      <b-colxx xl="3" lg="12" md="12" sm="12" class="mb-4">
        <b-card :title="$t('pages.vmb.dashboard.problems')" class="dashboard-link-list">
          <scale-loader
            :loading="!vmbObservationData || loadingElement === 1"
            :color="loader.color"
          ></scale-loader>
          <to-do-items
            :header="$t('pages.vmb.dashboard.problems')"
            v-if="vmbObservationData"
            :class="{ loadingElement: loadingElement === 1 }"
            :type="1"
            :data="orderedItems(vmbObservationData.problems)"
            detail-path="/vmb/problems"
            @clicked="onClickItem"
            @changed="onChangeItem"
          ></to-do-items>
        </b-card>
      </b-colxx>
      <b-colxx xl="3" lg="12" md="12" sm="12" class="mb-4">
        <b-card :title="$t('pages.vmb.dashboard.communications')" class="dashboard-link-list">
          <scale-loader
            :loading="!vmbObservationData || loadingElement === 4"
            :color="loader.color"
          ></scale-loader>
          <to-do-items
            :header="$t('pages.vmb.dashboard.communications')"
            v-if="vmbObservationData"
            :class="{ loadingElement: loadingElement === 4 }"
            :type="4"
            :data="orderedItems(vmbObservationData.communication)"
            detail-path="/vmb/communication"
            @clicked="onClickItem"
            @changed="onChangeItem"
          ></to-do-items>
        </b-card>
      </b-colxx>
      <b-colxx xl="6" lg="12" sm="12" md="12" class="mb-4">
        <b-card class="dashboard-filled-line-chart dashboard-link-list" no-body>
          <scale-loader :loading="!vmbCharts" :color="loader.color"></scale-loader>
          <div class="chart-title d-flex">
            <h5 class="d-inline align-self-center">{{ $t('pages.vmb.dashboard.accuracy') }}</h5>
            <router-link
              tag="a"
              to="/vmb/performance"
              v-if="vmbCharts"
              class="link-icon align-self-center ml-auto"
            >
              <i :class="navIcon" />
            </router-link>
          </div>
          <div class="chart card-body pt-0 pl-3 pr-3 pb-0" v-if="vmbCharts">
            <bar-chart :chart-data="accuracyChart" :options="accuracyChartOptions" :height="250" />
          </div>
        </b-card>
      </b-colxx>
      <b-colxx xl="3" lg="12" md="12" sm="12" class="mb-4">
        <b-card :title="$t('pages.vmb.dashboard.actions')" class="dashboard-link-list">
          <scale-loader
            :loading="!vmbObservationData || loadingElement === 2"
            :color="loader.color"
          ></scale-loader>
          <to-do-items
            :header="$t('pages.vmb.dashboard.actions')"
            v-if="vmbObservationData"
            :class="{ loadingElement: loadingElement === 2 }"
            :type="2"
            :data="orderedItems(vmbObservationData.actions)"
            detail-path="/vmb/actions"
            @clicked="onClickItem"
            @changed="onChangeItem"
          ></to-do-items>
        </b-card>
      </b-colxx>
      <b-colxx xl="3" lg="12" md="12" sm="12" class="mb-4">
        <b-card :title="$t('pages.vmb.dashboard.feedback')" class="dashboard-link-list">
          <scale-loader
            :loading="!vmbObservationData || loadingElement === 5"
            :color="loader.color"
          ></scale-loader>
          <to-do-items
            :header="$t('pages.vmb.dashboard.feedback')"
            v-if="vmbObservationData"
            :class="{ loadingElement: loadingElement === 5 }"
            :type="5"
            :data="orderedItems(vmbObservationData.feedback)"
            detail-path="/vmb/feedback"
            @clicked="onClickItem"
            @changed="onChangeItem"
          ></to-do-items>
        </b-card>
      </b-colxx>
      <b-colxx xl="6" lg="12" sm="12" md="12" class="mb-4">
        <b-card class="dashboard-filled-line-chart dashboard-link-list" no-body>
          <scale-loader :loading="!vmbCharts" :color="loader.color"></scale-loader>
          <router-link tag="a" to="/vmb/performance" v-if="vmbCharts" class="link-icon">
            <i :class="navIcon" />
          </router-link>
          <b-container class="chart-title">
            <div class="float-left float-none-xs">
              <div class="d-inline-block">
                <h5 class="d-inline">{{ $t('pages.vmb.dashboard.profitability') }}</h5>
              </div>
            </div>
          </b-container>
          <div class="chart card-body pt-0 pl-3 pr-3 pb-0" v-if="vmbCharts">
            <bar-chart
              :chart-data="profitabilityChart"
              :options="profitabilityChartOptions"
              :height="250"
            />
          </div>
        </b-card>
      </b-colxx>
      <b-colxx xl="3" lg="12" md="12" sm="12" class="mb-4">
        <b-card :title="$t('pages.vmb.dashboard.people')" class="dashboard-link-list">
          <scale-loader :loading="!vmbData" :color="loader.color"></scale-loader>
          <router-link tag="a" to="/vmb/people" v-if="vmbData && vmbData.people" class="link-icon">
            <i :class="navIcon" />
          </router-link>
          <vue-perfect-scrollbar
            class="scroll dashboard-list-with-user"
            :settings="{ suppressScrollX: true, wheelPropagation: false }"
          >
            <b-row v-if="vmbData && vmbData.people">
              <b-colxx
                v-for="item in vmbData.people"
                :key="item.id"
                xl="6"
                lg="6"
                md="6"
                class="pl-0 pr-0 mb-4 border-bottom text-center"
              >
                <p class="mb-1 pb-1">{{ item.details }}</p>
                <radial-progress-bar
                  :diameter="100"
                  :strokeWidth="10"
                  :completed-steps="parseInt(item.title)"
                  :total-steps="10"
                  class="mb-2"
                  :startColor="setChartColor(parseInt(item.title))"
                  :stopColor="setChartColor(parseInt(item.title))"
                >
                  <h1 class="mb-0 pb-0">{{ item.title }}</h1>
                </radial-progress-bar>
              </b-colxx>
            </b-row>
          </vue-perfect-scrollbar>
        </b-card>
      </b-colxx>

      <b-modal
        id="modalright"
        ref="modalright"
        :title="modalData.header"
        v-model="showModal"
        v-if="modalData"
        modal-class="modal-right"
        hide-footer
      >
        <h3 class="mb-4">
          <blockquote>{{ modalData.title }}</blockquote>
        </h3>
        <p class="font-weight-semibold text-one mb-1">Raised By</p>
        <p class="text-muted mb-3 text-small">{{ modalData.details }}</p>
        <p class="font-weight-semibold text-one mb-1">Day Raised</p>
        <p class="text-muted mb-3 text-small">{{ modalData.day_name }}</p>
        <p class="font-weight-semibold text-one mb-1">Round Raised</p>
        <p class="text-muted mb-3 text-small">{{ modalData.week_name }}</p>
        <p class="font-weight-semibold text-one mb-1">Status</p>
        <v-select
          :options="status.options"
          :value="modalData.status"
          label="label"
          :reduce="(status) => status.id"
          :dir="direction"
          @input="statusChanged($event)"
        />
      </b-modal>

      <b-modal
        id="modalVmb"
        ref="modalVmb"
        modal-class="modalVmb"
        size="md"
        hide-header
        hide-footer
      >
        <div class="mx-auto my-auto lodgement-container">
          <div class="d-flex mb-4">
            <h2 class="align-self-center pb-0 mb-0" v-if="currentUser">
              {{ currentUser.team }} {{ $t('pages.vmb.form.pre-huddle') }}
            </h2>
            <p class="align-self-center p-0 m-0 mx-2">
              <b-badge
                v-if="openTeamWeek"
                variant="outline-info"
                class="animate__animated animate__fadeInDown"
              >
                {{ openTeamWeek.open_week_name }}
              </b-badge>
            </p>
            <p class="align-self-center p-0 m-0">
              <b-badge
                v-if="openTeamWeek"
                variant="outline-new"
                class="animate__animated animate__fadeInDown"
              >
                {{ openTeamWeek.open_day_name }}
              </b-badge>
            </p>
          </div>
          <b-form @submit.prevent="formSubmit">
            <b-row>
              <b-col v-show="step === 1" class="col-12 survey animate__animated animate__fadeIn">
                <b-input-group class="mb-4">
                  <h3>{{ $t('pages.vmb.form.what-went-well.header') }}</h3>
                  <label class="pl-0 mb-2 col-12">
                    {{ $t('pages.vmb.form.what-went-well.title') }}
                  </label>
                  <b-form-tags
                    input-id="tags-whatWentWell"
                    v-model="newDashboardItems.whatWentWell"
                    :placeholder="$t('pages.vmb.form.placeholder')"
                    tag-variant="info"
                  ></b-form-tags>
                </b-input-group>
                <div class="d-flex justify-content-center align-items-center step-btn mx-2">
                  <b-button variant="primary" size="sm" @click.prevent="next()" class="btn-shadow">
                    {{ $t('pages.vmb.form.next-button') }}
                  </b-button>
                </div>
              </b-col>
              <b-col v-show="step === 2" class="col-12 survey animate__animated animate__fadeIn">
                <b-input-group class="mb-4">
                  <h3>{{ $t('pages.vmb.form.communications.header') }}</h3>
                  <label class="pl-0 mb-2 col-12">
                    {{ $t('pages.vmb.form.communications.title') }}
                  </label>
                  <b-form-tags
                    input-id="tags-communication"
                    v-model="newDashboardItems.communication"
                    :placeholder="$t('pages.vmb.form.placeholder')"
                    tag-variant="info"
                  ></b-form-tags>
                </b-input-group>
                <div class="d-flex justify-content-center align-items-center step-btn mx-2">
                  <b-button variant="light" size="sm" @click.prevent="prev()" class="btn-shadow">
                    {{ $t('pages.vmb.form.prev-button') }}
                  </b-button>
                  <b-button
                    variant="primary"
                    size="sm"
                    class="ml-2 btn-shadow"
                    @click.prevent="next()"
                  >
                    {{ $t('pages.vmb.form.next-button') }}
                  </b-button>
                </div>
              </b-col>
              <b-col v-show="step === 3" class="col-12 survey animate__animated animate__fadeIn">
                <b-input-group class="mb-4">
                  <h3>
                    {{ $t('pages.vmb.form.feedback.header') }}
                  </h3>
                  <label class="pl-0 mb-2 col-12">
                    {{ $t('pages.vmb.form.feedback.title') }}
                  </label>
                  <b-form-tags
                    input-id="tags-feedback"
                    v-model="newDashboardItems.feedback"
                    :placeholder="$t('pages.vmb.form.placeholder')"
                    tag-variant="info"
                  ></b-form-tags>
                </b-input-group>
                <div class="d-flex justify-content-end align-items-center step-btn mx-2">
                  <b-button variant="light" size="sm" @click.prevent="prev()" class="btn-shadow">
                    {{ $t('pages.vmb.form.prev-button') }}
                  </b-button>
                  <b-button
                    variant="primary"
                    size="sm"
                    class="ml-2 btn-shadow"
                    @click.prevent="next()"
                  >
                    {{ $t('pages.vmb.form.next-button') }}
                  </b-button>
                </div>
              </b-col>
              <b-col v-show="step === 4" class="col-12 survey animate__animated animate__fadeIn">
                <b-input-group class="mb-4">
                  <h3>
                    {{ $t('pages.vmb.form.problems.header') }}
                  </h3>
                  <label class="pl-0 mb-2 col-12">
                    {{ $t('pages.vmb.form.problems.title') }}
                  </label>
                  <b-form-tags
                    input-id="tags-problems"
                    v-model="newDashboardItems.problems"
                    :placeholder="$t('pages.vmb.form.placeholder')"
                    tag-variant="info"
                  ></b-form-tags>
                </b-input-group>
                <div class="d-flex justify-content-end align-items-center step-btn mx-2">
                  <b-button variant="light" size="sm" @click.prevent="prev()" class="btn-shadow">
                    {{ $t('pages.vmb.form.prev-button') }}
                  </b-button>
                  <b-button
                    variant="primary"
                    size="sm"
                    class="ml-2 btn-shadow"
                    @click.prevent="next()"
                  >
                    {{ $t('pages.vmb.form.next-button') }}
                  </b-button>
                </div>
              </b-col>
              <b-col v-show="step === 5" class="col-12 survey animate__animated animate__fadeIn">
                <b-input-group class="mb-4">
                  <h3>
                    {{ $t('pages.vmb.form.actions.header') }}
                  </h3>
                  <label class="pl-0 mb-2 col-12">
                    {{ $t('pages.vmb.form.actions.title') }}
                  </label>
                  <b-form-tags
                    input-id="tags-actions"
                    v-model="newDashboardItems.actions"
                    :placeholder="$t('pages.vmb.form.placeholder')"
                    tag-variant="info"
                  ></b-form-tags>
                </b-input-group>
                <div class="d-flex justify-content-end align-items-center step-btn mx-2">
                  <b-button variant="light" size="sm" @click.prevent="prev()" class="btn-shadow">
                    {{ $t('pages.vmb.form.prev-button') }}
                  </b-button>
                  <b-button
                    variant="primary"
                    size="sm"
                    class="ml-2 btn-shadow"
                    @click.prevent="next()"
                  >
                    {{ $t('pages.vmb.form.next-button') }}
                  </b-button>
                </div>
              </b-col>
              <b-col v-show="step === 6" class="col-12 survey animate__animated animate__fadeIn">
                <b-input-group class="mb-4">
                  <h3>
                    {{ $t('pages.vmb.form.people.header') }}
                  </h3>
                  <label class="pl-0 mb-4 col-12">
                    {{ $t('pages.vmb.form.people.title') }}
                  </label>
                  <b-col class="mt-4 mb-3">
                    <vue-slider
                      class="people-score-slider"
                      v-model="newDashboardItems.people"
                      :min="1"
                      :max="10"
                      :interval="1"
                      :tooltip="'always'"
                      :tooltip-placement="'top'"
                      :silent="true"
                    >
                      <template v-slot:tooltip="{ value, focus }">
                        <div
                          :class="[
                            'custom-tooltip',
                            `${setScoreVariant(value)}-tooltip`,
                            { focus }
                          ]"
                        >
                          <span>{{ value }}</span>
                        </div>
                      </template>
                    </vue-slider>
                  </b-col>
                  <b-row align-v="center" class="emo-group">
                    <b-col class="mb-2 pl-0 pr-0">
                      <img src="/assets/img/svg/emo-worst.svg" alt class="mb-1" />
                      <p>{{ $t('pages.vmb.form.people.help') }}</p>
                    </b-col>
                    <b-col class="mb-2 pl-0 pr-0">
                      <img src="/assets/img/svg/emo-poor.svg" alt class="mb-1" />
                      <p>{{ $t('pages.vmb.form.people.not-good') }}</p>
                    </b-col>
                    <b-col class="mb-2 pl-0 pr-0">
                      <img src="/assets/img/svg/emo-average.svg" alt class="mb-1" />
                      <p>{{ $t('pages.vmb.form.people.average') }}</p>
                    </b-col>
                    <b-col class="mb-2 pl-0 pr-0">
                      <img src="/assets/img/svg/emo-good.svg" alt class="mb-1" />
                      <p>{{ $t('pages.vmb.form.people.good') }}</p>
                    </b-col>
                    <b-col class="mb-2 pl-0 pr-0">
                      <img src="/assets/img/svg/emo-excellent.svg" alt class="mb-1" />
                      <p>{{ $t('pages.vmb.form.people.fantastic') }}</p>
                    </b-col>
                  </b-row>
                </b-input-group>
                <div class="d-flex justify-content-end align-items-center step-btn mx-2">
                  <b-button variant="light" size="sm" @click.prevent="prev()" class="btn-shadow">
                    {{ $t('pages.vmb.form.prev-button') }}
                  </b-button>
                  <processing-button
                    variant="new"
                    size="sm"
                    :label="$t('pages.vmb.form.submit-button')"
                    class="ml-2 align-self-center btn-shadow"
                    :processing="processing"
                  ></processing-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import BarChart from '@/components/Charts/Bar'
import InputTag from '@/components/Form/InputTag'
import RadialProgressCard from '@/components/Cards/RadialProgressCard'
import CommentItems from '@/components/Dashboard/CommentItems'
import ToDoItems from '@/components/Dashboard/ToDoItems'
import GradientWithRadialProgressCard from '@/components/Cards/GradientWithRadialProgressCard'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import VueSlider from 'vue-slider-component'
import RadialProgressBar from 'vue-radial-progress'
import 'vue-slider-component/theme/antd.css'
import { getDirection } from '@/utils'
import {
  claimsProcessedChartOptions,
  accuracyChartOptions,
  profitabilityChartOptions
} from '@/components/Dashboard/config'
import ScaleLoader from 'vue-spinner/src/ScaleLoader'
import { dataPreloadMixin } from '@/mixins/dataPreloadMixin'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import ProcessingButton from '@/components/Common/ProcessingButton'
import variables from '@/assets/css/sass/themes/_perform.sim.scss'
export default {
  components: {
    RadialProgressCard,
    BarChart,
    GradientWithRadialProgressCard,
    InputTag,
    CommentItems,
    ToDoItems,
    vSelect,
    VueSlider,
    RadialProgressBar,
    ScaleLoader,
    PulseLoader,
    ProcessingButton
  },
  data() {
    return {
      showModal: false,
      modalData: null,
      direction: getDirection().direction,
      navIcon: 'simple-icon-compass',
      step: 1,
      options: {
        height: 20,
        dotSize: 30,
        dotHeight: null,
        dotWidth: null
      },
      newDashboardItems: this.createNewDashboardItems(),
      claimsProcessedChartOptions: claimsProcessedChartOptions,
      accuracyChartOptions: accuracyChartOptions,
      profitabilityChartOptions: profitabilityChartOptions,
      processing: false,
      timer: null,
      loader: {
        color: '#2d7281'
      },
      loadingProblem: false,
      loadingAction: false,
      loadingElement: 0,
      chartColor: {
        positive: '#03BFAD',
        neutral: '#FFBA52',
        negative: '#F17363'
      },
      loadingDashboardData: false,
      currentTeamWeek: null,
      fill: variables.primaryColor
    }
  },
  mixins: [dataPreloadMixin],
  methods: {
    ...mapActions([
      'getStatus',
      'fetchVmbStatus',
      'fetchTeamWeeks',
      'fetchOpenTeamWeek',
      'fetchVmbAllData',
      'fetchVmbObservationData',
      'fetchVmbChartData',
      'submitVmbItems',
      'updateVmbObservationStatus'
    ]),
    ...mapMutations(['addDashboardItems']),
    createNewDashboardItems() {
      return {
        userId: '',
        weekId: '',
        dayId: '',
        whatWentWell: [],
        communication: [],
        feedback: [],
        performance: '',
        problems: [],
        actions: [],
        people: '6',
        anySupport: '',
        createdAt: ''
      }
    },
    onClickItem(value) {
      this.openModal(value)
    },
    hideModal(refname) {
      this.$refs[refname].hide()
    },
    openModal(data) {
      this.showModal = true
      this.modalData = data
    },
    async onChangeItem(item) {
      this.loadingElement = item.type
      await setTimeout(() => {
        this.updateVmbObservationStatus(item)
        this.loadingElement = 0
      }, 2000)
    },
    async statusChanged(value) {
      let md = this.modalData
      let obsUpdate = {
        id: md.id,
        type: md.type,
        status: value
      }
      await this.updateVmbObservationStatus(obsUpdate)
      this.hideModal('modalright')
    },
    prev() {
      this.step--
    },
    next() {
      this.step++
    },
    async getClaimsProcessedChart() {
      try {
        await this.$store.dispatch('getClaimsProcessedChart')
      } catch (e) {
        console.log(e.response)
      }
    },
    addProblem(event) {
      if (event.which === 13) {
        console.log(this.problems)
        this.problems.push({ value: '' })
      }
    },
    async formSubmit() {
      let self = this
      self.processing = true
      await self.submitVmbItems(self.newDashboardItems)
      self.processing = false
      await this.resetForm()
    },
    async resetForm() {
      let self = this
      self.createNewDashboardItems()
      self.processing = false
      self.step = 1
      self.hideModal('modalVmb')
    },
    async setCurrentTeamWeekDay() {
      let self = this
      self.newDashboardItems.userId = self.currentUser.id
      self.newDashboardItems.weekId = self.currentTeamWeek
        ? self.currentTeamWeek.open_week_id
        : null
      self.newDashboardItems.dayId = self.currentTeamWeek ? self.currentTeamWeek.open_day_id : null
    },
    async resetState() {
      this.$store.commit('resetState')
    },
    async getOpenTeamWeek() {
      let self = this
      await self.fetchOpenTeamWeek()
      self.currentTeamWeek = self.openTeamWeek
    },
    async getDashboardData() {
      let self = this
      await self.getStatus()
      self.loadingDashboardData = true
      await self.getOpenTeamWeek()
      await self.fetchVmbAllData()
      await self.fetchVmbObservationData()
      await self.fetchVmbChartData()
      self.loadingDashboardData = false
    },
    async onVmbClick() {
      let self = this
      await self.setCurrentTeamWeekDay()
      await self.$refs['modalVmb'].show()
    },
    setScoreVariant(score) {
      let variant = ''
      switch (Number(score)) {
        case 0:
        case 1:
        case 2:
          variant = 'help'
          break
        case 3:
        case 4:
          variant = 'not-good'
          break
        case 5:
        case 6:
          variant = 'average'
          break
        case 7:
        case 8:
          variant = 'good'
          break
        default:
          variant = 'fantastic'
      }
      return variant
    }
  },
  async created() {
    let self = this
    await self.getDashboardData()
    self.timer = setInterval(self.getDashboardData, 20000)
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'getMenuType',
      'status',
      'claimsProcessedChart',
      'accuracyChart',
      'profitabilityChart',
      'teamWeeks',
      'openTeamWeek',
      'vmbData',
      'vmbObservationData',
      'error',
      'vmbCharts'
    ]),
    orderedItems: function () {
      return (data) => {
        let filteredData = _.filter(data, (item) => item.status === 1)
        return _.orderBy(filteredData, ['status', 'week_id', 'day_id'], ['asc', 'desc', 'desc'])
      }
    },
    setChartColor: function () {
      return (data) => {
        if (data < 6) {
          return this.chartColor.negative
        } else if (data >= 6 && data < 8) {
          return this.chartColor.neutral
        } else {
          return this.chartColor.positive
        }
      }
    }
  },
  watch: {
    error(val) {
      if (val != null) {
        this.$notify('error', 'Dashboard Error', val, {
          duration: 3000,
          permanent: false
        })
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>
