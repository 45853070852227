<template>
  <div class="people">
    <b-row>
      <b-colxx xxs="12" class="pt-4 mb-5 dashboard-header">
        <h1 class="align-middle pb-0 mb-0">
          {{ $t('menu.vmb-menu.people') }}
        </h1>
        <span
          v-if="currentUser"
          class="avatar avatar-sm ml-2 align-middle"
          :style="{
            backgroundImage: 'url(' + currentUser.teamImg + ')'
          }"
        ></span>
      </b-colxx>
      <b-colxx lg="12" md="12" sm="12" class="mb-4">
        <!-- <b-card no-body> -->
        <scale-loader :loading="!vmbData" :color="loader.color"></scale-loader>
        <b-row v-if="vmbData">
          <b-colxx
            v-for="item in orderedItems(vmbData.people)"
            :key="item.id"
            xl="3"
            lg="4"
            md="6"
            sm="12"
            class="mb-4 text-center"
          >
            <b-card>
              <h3 class="mb-1 pb-1">{{ item.details }}</h3>
              <radial-progress-bar
                :diameter="200"
                :strokeWidth="20"
                :completed-steps="parseInt(item.title)"
                :total-steps="10"
                class="mb-2"
                :startColor="setChartColor(parseInt(item.title))"
                :stopColor="setChartColor(parseInt(item.title))"
                animatedSpeed="1000"
              >
                <span class="mb-0 pb-0 score">{{ item.title }}</span>
              </radial-progress-bar>
            </b-card>
          </b-colxx>
        </b-row>
        <!-- </b-card> -->
      </b-colxx>
    </b-row>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { dataPreloadMixin } from '../../../mixins/dataPreloadMixin'
import ScaleLoader from 'vue-spinner/src/ScaleLoader'
export default {
  components: {
    RadialProgressBar,
    ScaleLoader
  },
  data() {
    return {
      loader: {
        color: '#2d7281'
      },
      chartColor: {
        positive: '#03BFAD',
        neutral: '#FFBA52',
        negative: '#F17363'
      }
    }
  },
  mixins: [dataPreloadMixin],
  created() {
    this.getVmbData()
  },
  methods: {
    // ...mapActions(["getPeople"])
  },
  computed: {
    ...mapGetters(['vmbData', 'currentTeamWeek', 'currentUser']),
    orderedItems: function () {
      return (data) => _.orderBy(data, ['details'], ['asc'])
    },
    setChartColor: function () {
      return (data) => {
        if (data < 6) {
          return this.chartColor.negative
        } else if (data >= 6 && data < 8) {
          return this.chartColor.neutral
        } else {
          return this.chartColor.positive
        }
      }
    }
  }
}
</script>
