var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", [
    _c(
      "ul",
      { staticClass: "pagination justify-content-center pagination-sm" },
      [
        _c(
          "li",
          { class: { disabled: _vm.isOnFirstPage, "page-item": true } },
          [
            _c(
              "a",
              {
                staticClass: "page-link",
                attrs: { href: "" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.loadPage("prev")
                  },
                },
              },
              [_vm._m(0)]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.notEnoughPages
          ? _vm._l(_vm.totalPage, function (n) {
              return _c(
                "li",
                {
                  key: n,
                  class: { active: _vm.isCurrentPage(n), "page-item": true },
                },
                [
                  _c("a", {
                    staticClass: "page-link",
                    domProps: { innerHTML: _vm._s(n) },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.loadPage(n)
                      },
                    },
                  }),
                ]
              )
            })
          : _vm._l(_vm.windowSize, function (n) {
              return _c(
                "li",
                {
                  key: n,
                  class: {
                    active: _vm.isCurrentPage(_vm.windowStart + n - 1),
                    "page-item": true,
                  },
                },
                [
                  _c("a", {
                    staticClass: "page-link",
                    domProps: { innerHTML: _vm._s(_vm.windowStart + n - 1) },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.loadPage(_vm.windowStart + n - 1)
                      },
                    },
                  }),
                ]
              )
            }),
        _vm._v(" "),
        _c("li", { class: { disabled: _vm.isOnLastPage, "page-item": true } }, [
          _c(
            "a",
            {
              staticClass: "page-link",
              attrs: { href: "" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.loadPage("next")
                },
              },
            },
            [_vm._m(1)]
          ),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "simple-icon-arrow-left" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "simple-icon-arrow-right" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }