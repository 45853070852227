<template>
  <div class="survey-observation-details">
    <div class="d-flex mb-4 dashboard-header">
      <h2 class="align-self-center pb-0 mb-0">{{ $route.meta.title }}</h2>
      <span
        v-if="currentUser"
        class="avatar avatar-sm mx-3 align-self-center"
        :style="{
          backgroundImage: 'url(' + currentUser.teamImg + ')'
        }"
      ></span>
      <div class="ml-auto align-self-center">
        <switches
          v-if="vmbObservationData"
          v-model="showAll"
          theme="custom"
          color="primary vue-switcher-small text-center"
          class="mb-0"
          label="All"
          @input="onSwitch"
        ></switches>
      </div>
    </div>
    <div v-if="vmbObservationData">
      <b-row>
        <b-col
          xl="3"
          lg="6"
          md="12"
          sm="12"
          class="mb-4"
          v-for="(item, index) in tableData"
          :key="index"
        >
          <b-card no-body class="detail-card">
            <b-card-body>
              <div class="action-toggle text-center">
                <toggle-button
                  :value="getCurrentStatus(item.status)"
                  :labels="{ checked: 'Open', unchecked: 'Complete' }"
                  :color="'#2d7281'"
                  :width="80"
                  :sync="true"
                  @change="onCompleteSwitch($event, item)"
                />
              </div>
              <b-container>
                <b-row class="justify-content-center text-center">
                  <b-col class="col-12">
                    <b-avatar
                      :src="item.thumb"
                      size="3.5em"
                      :text="getNameInitials(item.details)"
                    ></b-avatar>
                  </b-col>
                  <b-col class="col-12 mt-3 p-0">
                    <h6 class="pb-0">{{ item.details }}</h6>
                    <b-badge class="ml-2" variant="outline-danger">
                      {{ item.day_name }}
                    </b-badge>
                    <b-badge class="ml-1" variant="outline-primary">
                      {{ item.week_name }}
                    </b-badge>
                    <vue-perfect-scrollbar
                      class="scroll dashboard-list-with-comments mt-3 p-0"
                      :settings="{
                        suppressScrollX: true,
                        wheelPropagation: false
                      }"
                    >
                      <h6>{{ item.title }}</h6>
                    </vue-perfect-scrollbar>
                  </b-col>
                </b-row>
              </b-container>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-else class="spinner">
      <ring-loader :color="loader.color"></ring-loader>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '@/components/Common/VuetablePaginationBootstrap'
import { mapGetters } from 'vuex'
import { dataPreloadMixin } from '@/mixins/dataPreloadMixin'
import { helperMixin } from '@/mixins/helperMixin'
import ScaleLoader from 'vue-spinner/src/ScaleLoader'
import Switches from 'vue-switches'
import RingLoader from 'vue-spinner/src/RingLoader'
export default {
  components: {
    vSelect,
    Vuetable,
    VuetablePaginationBootstrap,
    ScaleLoader,
    Switches,
    RingLoader
  },
  mixins: [helperMixin, dataPreloadMixin],
  data() {
    return {
      loader: {
        color: '#2d7281'
      },
      switches: {
        primary: false,
        secondary: false,
        primaryInverse: false,
        secondaryInverse: false
      },
      tableData: null,
      showAll: false
    }
  },
  created() {
    this.fetchVmbObservation()
    this.timer = setInterval(this.fetchVmbObservation, 30000)
  },
  computed: {
    ...mapGetters(['vmbObservationData', 'status', 'currentUser'])
  },
  methods: {
    async statusChanged(value, item) {
      let obsUpdate = {
        id: item.id,
        type: this.$route.meta.id,
        status: value
      }
      await this.updateVmbObservationStatus(obsUpdate)
    },
    async fetchVmbObservation() {
      await this.getVmbStatus()
      await this.getVmbObservationData()
    },
    async fetchVmbObservationByStatus(value) {
      let vmbData = this.vmbObservationData[this.$route.name]

      // show all
      if (!value) {
        vmbData = vmbData.filter((item) => item.status === 1)
      }

      // sort records
      this.tableData = _.orderBy(
        vmbData,
        ['status', 'week_id', 'day_id', 'details'],
        ['asc', 'desc', 'desc', 'asc']
      )
    },
    async onSwitch(event) {
      await this.fetchVmbObservationByStatus(event)
    },
    getCurrentStatus(item) {
      return item === 2 ? true : false
    },
    onDisplaySwitch(value) {
      console.log(value)
      let vmbData = this.vmbObservationData[this.$route.name]

      // show all
      if (!value) {
        vmbData = vmbData.filter((item) => item.status === 1)
      }

      // sort records
      this.tableData = _.orderBy(
        vmbData,
        ['status', 'week_id', 'day_id', 'details'],
        ['asc', 'desc', 'desc', 'asc']
      )
    },
    async onCompleteSwitch(event, item) {
      let value = event.value ? 2 : 1
      item.status = value
      let obsUpdate = {
        id: item.id,
        type: this.$route.meta.id,
        status: value
      }
      await this.updateVmbObservationStatus(obsUpdate)

      await this.onSwitch(this.showAll)
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>
