var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboards" },
    [
      _c(
        "div",
        { staticClass: "d-flex mb-2" },
        [
          _c("h2", { staticClass: "align-self-center pb-0 mb-0" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.currentUser.team) +
                " " +
                _vm._s(_vm.$t("pages.vmb.dashboard.title")) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "align-self-center p-0 m-0 mx-2" },
            [
              _vm.openTeamWeek
                ? _c(
                    "b-badge",
                    {
                      staticClass: "animate__animated animate__fadeInDown",
                      attrs: { variant: "outline-info" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.openTeamWeek.open_week_name) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "align-self-center p-0 m-0" },
            [
              _vm.openTeamWeek
                ? _c(
                    "b-badge",
                    {
                      staticClass: "animate__animated animate__fadeInDown",
                      attrs: { variant: "outline-new" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.openTeamWeek.open_day_name) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.openTeamWeek
            ? _c(
                "b-button",
                {
                  staticClass: "ml-auto align-self-center btn-shadow",
                  attrs: { size: "sm", variant: "new" },
                  on: { click: _vm.onVmbClick },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("pages.vmb.dashboard.update-vmb")) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-colxx",
            {
              staticClass: "mb-4",
              attrs: { xl: "3", lg: "12", md: "12", sm: "12" },
            },
            [
              _c(
                "b-card",
                {
                  staticClass: "dashboard-link-list",
                  attrs: {
                    title: _vm.$t("pages.vmb.dashboard.what-went-well"),
                  },
                },
                [
                  _c("scale-loader", {
                    attrs: {
                      loading:
                        !_vm.vmbObservationData || _vm.loadingElement === 3,
                      color: _vm.loader.color,
                    },
                  }),
                  _vm._v(" "),
                  _vm.vmbObservationData
                    ? _c("to-do-items", {
                        class: { loadingElement: _vm.loadingElement === 3 },
                        attrs: {
                          header: _vm.$t("pages.vmb.dashboard.what-went-well"),
                          type: 3,
                          data: _vm.orderedItems(
                            _vm.vmbObservationData.what_went_well
                          ),
                          "detail-path": "/vmb/what-went-well",
                        },
                        on: {
                          clicked: _vm.onClickItem,
                          changed: _vm.onChangeItem,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-colxx",
            {
              staticClass: "mb-4",
              attrs: { xl: "6", lg: "12", sm: "12", md: "12" },
            },
            [
              _c(
                "b-card",
                {
                  staticClass:
                    "dashboard-filled-line-chart dashboard-link-list",
                  attrs: { "no-body": "" },
                },
                [
                  _c("scale-loader", {
                    attrs: { loading: !_vm.vmbCharts, color: _vm.loader.color },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chart-title d-flex" },
                    [
                      _c("h5", { staticClass: "d-inline align-self-center" }, [
                        _vm._v(_vm._s(_vm.$t("pages.vmb.dashboard.volume"))),
                      ]),
                      _vm._v(" "),
                      _vm.vmbCharts
                        ? _c(
                            "router-link",
                            {
                              staticClass:
                                "link-icon align-self-center ml-auto",
                              attrs: { tag: "a", to: "/vmb/performance" },
                            },
                            [_c("i", { class: _vm.navIcon })]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chart card-body pt-0 pl-3 pr-3 pb-0" },
                    [
                      _vm.vmbCharts
                        ? _c("bar-chart", {
                            attrs: {
                              "chart-data": _vm.claimsProcessedChart,
                              options: _vm.claimsProcessedChartOptions,
                              height: 250,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-colxx",
            {
              staticClass: "mb-4",
              attrs: { xl: "3", lg: "12", md: "12", sm: "12" },
            },
            [
              _c(
                "b-card",
                {
                  staticClass: "dashboard-link-list",
                  attrs: { title: _vm.$t("pages.vmb.dashboard.problems") },
                },
                [
                  _c("scale-loader", {
                    attrs: {
                      loading:
                        !_vm.vmbObservationData || _vm.loadingElement === 1,
                      color: _vm.loader.color,
                    },
                  }),
                  _vm._v(" "),
                  _vm.vmbObservationData
                    ? _c("to-do-items", {
                        class: { loadingElement: _vm.loadingElement === 1 },
                        attrs: {
                          header: _vm.$t("pages.vmb.dashboard.problems"),
                          type: 1,
                          data: _vm.orderedItems(
                            _vm.vmbObservationData.problems
                          ),
                          "detail-path": "/vmb/problems",
                        },
                        on: {
                          clicked: _vm.onClickItem,
                          changed: _vm.onChangeItem,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-colxx",
            {
              staticClass: "mb-4",
              attrs: { xl: "3", lg: "12", md: "12", sm: "12" },
            },
            [
              _c(
                "b-card",
                {
                  staticClass: "dashboard-link-list",
                  attrs: {
                    title: _vm.$t("pages.vmb.dashboard.communications"),
                  },
                },
                [
                  _c("scale-loader", {
                    attrs: {
                      loading:
                        !_vm.vmbObservationData || _vm.loadingElement === 4,
                      color: _vm.loader.color,
                    },
                  }),
                  _vm._v(" "),
                  _vm.vmbObservationData
                    ? _c("to-do-items", {
                        class: { loadingElement: _vm.loadingElement === 4 },
                        attrs: {
                          header: _vm.$t("pages.vmb.dashboard.communications"),
                          type: 4,
                          data: _vm.orderedItems(
                            _vm.vmbObservationData.communication
                          ),
                          "detail-path": "/vmb/communication",
                        },
                        on: {
                          clicked: _vm.onClickItem,
                          changed: _vm.onChangeItem,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-colxx",
            {
              staticClass: "mb-4",
              attrs: { xl: "6", lg: "12", sm: "12", md: "12" },
            },
            [
              _c(
                "b-card",
                {
                  staticClass:
                    "dashboard-filled-line-chart dashboard-link-list",
                  attrs: { "no-body": "" },
                },
                [
                  _c("scale-loader", {
                    attrs: { loading: !_vm.vmbCharts, color: _vm.loader.color },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chart-title d-flex" },
                    [
                      _c("h5", { staticClass: "d-inline align-self-center" }, [
                        _vm._v(_vm._s(_vm.$t("pages.vmb.dashboard.accuracy"))),
                      ]),
                      _vm._v(" "),
                      _vm.vmbCharts
                        ? _c(
                            "router-link",
                            {
                              staticClass:
                                "link-icon align-self-center ml-auto",
                              attrs: { tag: "a", to: "/vmb/performance" },
                            },
                            [_c("i", { class: _vm.navIcon })]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.vmbCharts
                    ? _c(
                        "div",
                        { staticClass: "chart card-body pt-0 pl-3 pr-3 pb-0" },
                        [
                          _c("bar-chart", {
                            attrs: {
                              "chart-data": _vm.accuracyChart,
                              options: _vm.accuracyChartOptions,
                              height: 250,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-colxx",
            {
              staticClass: "mb-4",
              attrs: { xl: "3", lg: "12", md: "12", sm: "12" },
            },
            [
              _c(
                "b-card",
                {
                  staticClass: "dashboard-link-list",
                  attrs: { title: _vm.$t("pages.vmb.dashboard.actions") },
                },
                [
                  _c("scale-loader", {
                    attrs: {
                      loading:
                        !_vm.vmbObservationData || _vm.loadingElement === 2,
                      color: _vm.loader.color,
                    },
                  }),
                  _vm._v(" "),
                  _vm.vmbObservationData
                    ? _c("to-do-items", {
                        class: { loadingElement: _vm.loadingElement === 2 },
                        attrs: {
                          header: _vm.$t("pages.vmb.dashboard.actions"),
                          type: 2,
                          data: _vm.orderedItems(
                            _vm.vmbObservationData.actions
                          ),
                          "detail-path": "/vmb/actions",
                        },
                        on: {
                          clicked: _vm.onClickItem,
                          changed: _vm.onChangeItem,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-colxx",
            {
              staticClass: "mb-4",
              attrs: { xl: "3", lg: "12", md: "12", sm: "12" },
            },
            [
              _c(
                "b-card",
                {
                  staticClass: "dashboard-link-list",
                  attrs: { title: _vm.$t("pages.vmb.dashboard.feedback") },
                },
                [
                  _c("scale-loader", {
                    attrs: {
                      loading:
                        !_vm.vmbObservationData || _vm.loadingElement === 5,
                      color: _vm.loader.color,
                    },
                  }),
                  _vm._v(" "),
                  _vm.vmbObservationData
                    ? _c("to-do-items", {
                        class: { loadingElement: _vm.loadingElement === 5 },
                        attrs: {
                          header: _vm.$t("pages.vmb.dashboard.feedback"),
                          type: 5,
                          data: _vm.orderedItems(
                            _vm.vmbObservationData.feedback
                          ),
                          "detail-path": "/vmb/feedback",
                        },
                        on: {
                          clicked: _vm.onClickItem,
                          changed: _vm.onChangeItem,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-colxx",
            {
              staticClass: "mb-4",
              attrs: { xl: "6", lg: "12", sm: "12", md: "12" },
            },
            [
              _c(
                "b-card",
                {
                  staticClass:
                    "dashboard-filled-line-chart dashboard-link-list",
                  attrs: { "no-body": "" },
                },
                [
                  _c("scale-loader", {
                    attrs: { loading: !_vm.vmbCharts, color: _vm.loader.color },
                  }),
                  _vm._v(" "),
                  _vm.vmbCharts
                    ? _c(
                        "router-link",
                        {
                          staticClass: "link-icon",
                          attrs: { tag: "a", to: "/vmb/performance" },
                        },
                        [_c("i", { class: _vm.navIcon })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("b-container", { staticClass: "chart-title" }, [
                    _c("div", { staticClass: "float-left float-none-xs" }, [
                      _c("div", { staticClass: "d-inline-block" }, [
                        _c("h5", { staticClass: "d-inline" }, [
                          _vm._v(
                            _vm._s(_vm.$t("pages.vmb.dashboard.profitability"))
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.vmbCharts
                    ? _c(
                        "div",
                        { staticClass: "chart card-body pt-0 pl-3 pr-3 pb-0" },
                        [
                          _c("bar-chart", {
                            attrs: {
                              "chart-data": _vm.profitabilityChart,
                              options: _vm.profitabilityChartOptions,
                              height: 250,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-colxx",
            {
              staticClass: "mb-4",
              attrs: { xl: "3", lg: "12", md: "12", sm: "12" },
            },
            [
              _c(
                "b-card",
                {
                  staticClass: "dashboard-link-list",
                  attrs: { title: _vm.$t("pages.vmb.dashboard.people") },
                },
                [
                  _c("scale-loader", {
                    attrs: { loading: !_vm.vmbData, color: _vm.loader.color },
                  }),
                  _vm._v(" "),
                  _vm.vmbData && _vm.vmbData.people
                    ? _c(
                        "router-link",
                        {
                          staticClass: "link-icon",
                          attrs: { tag: "a", to: "/vmb/people" },
                        },
                        [_c("i", { class: _vm.navIcon })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "vue-perfect-scrollbar",
                    {
                      staticClass: "scroll dashboard-list-with-user",
                      attrs: {
                        settings: {
                          suppressScrollX: true,
                          wheelPropagation: false,
                        },
                      },
                    },
                    [
                      _vm.vmbData && _vm.vmbData.people
                        ? _c(
                            "b-row",
                            _vm._l(_vm.vmbData.people, function (item) {
                              return _c(
                                "b-colxx",
                                {
                                  key: item.id,
                                  staticClass:
                                    "pl-0 pr-0 mb-4 border-bottom text-center",
                                  attrs: { xl: "6", lg: "6", md: "6" },
                                },
                                [
                                  _c("p", { staticClass: "mb-1 pb-1" }, [
                                    _vm._v(_vm._s(item.details)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "radial-progress-bar",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        diameter: 100,
                                        strokeWidth: 10,
                                        "completed-steps": parseInt(item.title),
                                        "total-steps": 10,
                                        startColor: _vm.setChartColor(
                                          parseInt(item.title)
                                        ),
                                        stopColor: _vm.setChartColor(
                                          parseInt(item.title)
                                        ),
                                      },
                                    },
                                    [
                                      _c("h1", { staticClass: "mb-0 pb-0" }, [
                                        _vm._v(_vm._s(item.title)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.modalData
            ? _c(
                "b-modal",
                {
                  ref: "modalright",
                  attrs: {
                    id: "modalright",
                    title: _vm.modalData.header,
                    "modal-class": "modal-right",
                    "hide-footer": "",
                  },
                  model: {
                    value: _vm.showModal,
                    callback: function ($$v) {
                      _vm.showModal = $$v
                    },
                    expression: "showModal",
                  },
                },
                [
                  _c("h3", { staticClass: "mb-4" }, [
                    _c("blockquote", [_vm._v(_vm._s(_vm.modalData.title))]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "font-weight-semibold text-one mb-1" },
                    [_vm._v("Raised By")]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-muted mb-3 text-small" }, [
                    _vm._v(_vm._s(_vm.modalData.details)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "font-weight-semibold text-one mb-1" },
                    [_vm._v("Day Raised")]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-muted mb-3 text-small" }, [
                    _vm._v(_vm._s(_vm.modalData.day_name)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "font-weight-semibold text-one mb-1" },
                    [_vm._v("Round Raised")]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-muted mb-3 text-small" }, [
                    _vm._v(_vm._s(_vm.modalData.week_name)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "font-weight-semibold text-one mb-1" },
                    [_vm._v("Status")]
                  ),
                  _vm._v(" "),
                  _c("v-select", {
                    attrs: {
                      options: _vm.status.options,
                      value: _vm.modalData.status,
                      label: "label",
                      reduce: (status) => status.id,
                      dir: _vm.direction,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.statusChanged($event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-modal",
            {
              ref: "modalVmb",
              attrs: {
                id: "modalVmb",
                "modal-class": "modalVmb",
                size: "md",
                "hide-header": "",
                "hide-footer": "",
              },
            },
            [
              _c(
                "div",
                { staticClass: "mx-auto my-auto lodgement-container" },
                [
                  _c("div", { staticClass: "d-flex mb-4" }, [
                    _vm.currentUser
                      ? _c(
                          "h2",
                          { staticClass: "align-self-center pb-0 mb-0" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.currentUser.team) +
                                " " +
                                _vm._s(_vm.$t("pages.vmb.form.pre-huddle")) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "align-self-center p-0 m-0 mx-2" },
                      [
                        _vm.openTeamWeek
                          ? _c(
                              "b-badge",
                              {
                                staticClass:
                                  "animate__animated animate__fadeInDown",
                                attrs: { variant: "outline-info" },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.openTeamWeek.open_week_name) +
                                    "\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "align-self-center p-0 m-0" },
                      [
                        _vm.openTeamWeek
                          ? _c(
                              "b-badge",
                              {
                                staticClass:
                                  "animate__animated animate__fadeInDown",
                                attrs: { variant: "outline-new" },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.openTeamWeek.open_day_name) +
                                    "\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.formSubmit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.step === 1,
                                  expression: "step === 1",
                                },
                              ],
                              staticClass:
                                "col-12 survey animate__animated animate__fadeIn",
                            },
                            [
                              _c(
                                "b-input-group",
                                { staticClass: "mb-4" },
                                [
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "pages.vmb.form.what-went-well.header"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    { staticClass: "pl-0 mb-2 col-12" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "pages.vmb.form.what-went-well.title"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("b-form-tags", {
                                    attrs: {
                                      "input-id": "tags-whatWentWell",
                                      placeholder: _vm.$t(
                                        "pages.vmb.form.placeholder"
                                      ),
                                      "tag-variant": "info",
                                    },
                                    model: {
                                      value: _vm.newDashboardItems.whatWentWell,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.newDashboardItems,
                                          "whatWentWell",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "newDashboardItems.whatWentWell",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center align-items-center step-btn mx-2",
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-shadow",
                                      attrs: { variant: "primary", size: "sm" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.next()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("pages.vmb.form.next-button")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.step === 2,
                                  expression: "step === 2",
                                },
                              ],
                              staticClass:
                                "col-12 survey animate__animated animate__fadeIn",
                            },
                            [
                              _c(
                                "b-input-group",
                                { staticClass: "mb-4" },
                                [
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "pages.vmb.form.communications.header"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    { staticClass: "pl-0 mb-2 col-12" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "pages.vmb.form.communications.title"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("b-form-tags", {
                                    attrs: {
                                      "input-id": "tags-communication",
                                      placeholder: _vm.$t(
                                        "pages.vmb.form.placeholder"
                                      ),
                                      "tag-variant": "info",
                                    },
                                    model: {
                                      value:
                                        _vm.newDashboardItems.communication,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.newDashboardItems,
                                          "communication",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "newDashboardItems.communication",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center align-items-center step-btn mx-2",
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-shadow",
                                      attrs: { variant: "light", size: "sm" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.prev()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("pages.vmb.form.prev-button")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "ml-2 btn-shadow",
                                      attrs: { variant: "primary", size: "sm" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.next()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("pages.vmb.form.next-button")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.step === 3,
                                  expression: "step === 3",
                                },
                              ],
                              staticClass:
                                "col-12 survey animate__animated animate__fadeIn",
                            },
                            [
                              _c(
                                "b-input-group",
                                { staticClass: "mb-4" },
                                [
                                  _c("h3", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "pages.vmb.form.feedback.header"
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    { staticClass: "pl-0 mb-2 col-12" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "pages.vmb.form.feedback.title"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("b-form-tags", {
                                    attrs: {
                                      "input-id": "tags-feedback",
                                      placeholder: _vm.$t(
                                        "pages.vmb.form.placeholder"
                                      ),
                                      "tag-variant": "info",
                                    },
                                    model: {
                                      value: _vm.newDashboardItems.feedback,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.newDashboardItems,
                                          "feedback",
                                          $$v
                                        )
                                      },
                                      expression: "newDashboardItems.feedback",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-end align-items-center step-btn mx-2",
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-shadow",
                                      attrs: { variant: "light", size: "sm" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.prev()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("pages.vmb.form.prev-button")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "ml-2 btn-shadow",
                                      attrs: { variant: "primary", size: "sm" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.next()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("pages.vmb.form.next-button")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.step === 4,
                                  expression: "step === 4",
                                },
                              ],
                              staticClass:
                                "col-12 survey animate__animated animate__fadeIn",
                            },
                            [
                              _c(
                                "b-input-group",
                                { staticClass: "mb-4" },
                                [
                                  _c("h3", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "pages.vmb.form.problems.header"
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    { staticClass: "pl-0 mb-2 col-12" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "pages.vmb.form.problems.title"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("b-form-tags", {
                                    attrs: {
                                      "input-id": "tags-problems",
                                      placeholder: _vm.$t(
                                        "pages.vmb.form.placeholder"
                                      ),
                                      "tag-variant": "info",
                                    },
                                    model: {
                                      value: _vm.newDashboardItems.problems,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.newDashboardItems,
                                          "problems",
                                          $$v
                                        )
                                      },
                                      expression: "newDashboardItems.problems",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-end align-items-center step-btn mx-2",
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-shadow",
                                      attrs: { variant: "light", size: "sm" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.prev()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("pages.vmb.form.prev-button")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "ml-2 btn-shadow",
                                      attrs: { variant: "primary", size: "sm" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.next()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("pages.vmb.form.next-button")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.step === 5,
                                  expression: "step === 5",
                                },
                              ],
                              staticClass:
                                "col-12 survey animate__animated animate__fadeIn",
                            },
                            [
                              _c(
                                "b-input-group",
                                { staticClass: "mb-4" },
                                [
                                  _c("h3", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "pages.vmb.form.actions.header"
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    { staticClass: "pl-0 mb-2 col-12" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "pages.vmb.form.actions.title"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("b-form-tags", {
                                    attrs: {
                                      "input-id": "tags-actions",
                                      placeholder: _vm.$t(
                                        "pages.vmb.form.placeholder"
                                      ),
                                      "tag-variant": "info",
                                    },
                                    model: {
                                      value: _vm.newDashboardItems.actions,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.newDashboardItems,
                                          "actions",
                                          $$v
                                        )
                                      },
                                      expression: "newDashboardItems.actions",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-end align-items-center step-btn mx-2",
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-shadow",
                                      attrs: { variant: "light", size: "sm" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.prev()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("pages.vmb.form.prev-button")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "ml-2 btn-shadow",
                                      attrs: { variant: "primary", size: "sm" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.next()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("pages.vmb.form.next-button")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.step === 6,
                                  expression: "step === 6",
                                },
                              ],
                              staticClass:
                                "col-12 survey animate__animated animate__fadeIn",
                            },
                            [
                              _c(
                                "b-input-group",
                                { staticClass: "mb-4" },
                                [
                                  _c("h3", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t("pages.vmb.form.people.header")
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    { staticClass: "pl-0 mb-4 col-12" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "pages.vmb.form.people.title"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    { staticClass: "mt-4 mb-3" },
                                    [
                                      _c("vue-slider", {
                                        staticClass: "people-score-slider",
                                        attrs: {
                                          min: 1,
                                          max: 10,
                                          interval: 1,
                                          tooltip: "always",
                                          "tooltip-placement": "top",
                                          silent: true,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "tooltip",
                                            fn: function ({ value, focus }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    class: [
                                                      "custom-tooltip",
                                                      `${_vm.setScoreVariant(
                                                        value
                                                      )}-tooltip`,
                                                      { focus },
                                                    ],
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(_vm._s(value)),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                        model: {
                                          value: _vm.newDashboardItems.people,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.newDashboardItems,
                                              "people",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "newDashboardItems.people",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-row",
                                    {
                                      staticClass: "emo-group",
                                      attrs: { "align-v": "center" },
                                    },
                                    [
                                      _c(
                                        "b-col",
                                        { staticClass: "mb-2 pl-0 pr-0" },
                                        [
                                          _c("img", {
                                            staticClass: "mb-1",
                                            attrs: {
                                              src: "/assets/img/svg/emo-worst.svg",
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pages.vmb.form.people.help"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        { staticClass: "mb-2 pl-0 pr-0" },
                                        [
                                          _c("img", {
                                            staticClass: "mb-1",
                                            attrs: {
                                              src: "/assets/img/svg/emo-poor.svg",
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pages.vmb.form.people.not-good"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        { staticClass: "mb-2 pl-0 pr-0" },
                                        [
                                          _c("img", {
                                            staticClass: "mb-1",
                                            attrs: {
                                              src: "/assets/img/svg/emo-average.svg",
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pages.vmb.form.people.average"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        { staticClass: "mb-2 pl-0 pr-0" },
                                        [
                                          _c("img", {
                                            staticClass: "mb-1",
                                            attrs: {
                                              src: "/assets/img/svg/emo-good.svg",
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pages.vmb.form.people.good"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        { staticClass: "mb-2 pl-0 pr-0" },
                                        [
                                          _c("img", {
                                            staticClass: "mb-1",
                                            attrs: {
                                              src: "/assets/img/svg/emo-excellent.svg",
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pages.vmb.form.people.fantastic"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-end align-items-center step-btn mx-2",
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-shadow",
                                      attrs: { variant: "light", size: "sm" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.prev()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("pages.vmb.form.prev-button")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("processing-button", {
                                    staticClass:
                                      "ml-2 align-self-center btn-shadow",
                                    attrs: {
                                      variant: "new",
                                      size: "sm",
                                      label: _vm.$t(
                                        "pages.vmb.form.submit-button"
                                      ),
                                      processing: _vm.processing,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }