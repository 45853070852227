<template>
  <div>
    <div class="d-flex mb-4 dashboard-header">
      <h2 class="align-self-center pb-0 mb-0">
        {{ $t('menu.vmb-menu.performance') }}
      </h2>
      <span
        v-if="currentUser"
        class="avatar avatar-sm mx-3 align-self-center"
        :style="{
          backgroundImage: 'url(' + currentUser.teamImg + ')'
        }"
      ></span>
    </div>
    <b-row>
      <b-colxx xl="4" lg="12" sm="12" md="12" class="mb-4">
        <b-card class="dashboard-filled-line-chart dashboard-link-list" no-body>
          <b-container class="chart-title">
            <div class="float-left float-none-xs">
              <div class="d-inline-block">
                <h5 class="d-inline">
                  {{ $t('pages.vmb.dashboard.volume') }}
                </h5>
              </div>
            </div>
          </b-container>
          <div class="chart card-body pt-0 pl-3 pr-3 pb-0">
            <scale-loader :loading="!vmbCharts" :color="loader.color"></scale-loader>
            <bar-chart
              v-if="vmbCharts"
              :chart-data="claimsProcessedChart"
              :options="claimsProcessedChartOptions"
              :height="250"
            />
          </div>
        </b-card>
      </b-colxx>
      <b-colxx xl="4" lg="12" sm="12" md="12" class="mb-4">
        <b-card class="dashboard-filled-line-chart dashboard-link-list" no-body>
          <b-container class="chart-title">
            <div class="float-left float-none-xs">
              <div class="d-inline-block">
                <h5 class="d-inline">
                  {{ $t('pages.vmb.dashboard.accuracy') }}
                </h5>
              </div>
            </div>
          </b-container>
          <div class="chart card-body pt-0 pl-3 pr-3 pb-0">
            <scale-loader :loading="!vmbCharts" :color="loader.color"></scale-loader>
            <bar-chart
              v-if="vmbCharts"
              :chart-data="accuracyChart"
              :options="accuracyChartOptions"
              :height="250"
            />
          </div>
        </b-card>
      </b-colxx>
      <b-colxx xl="4" lg="12" sm="12" md="12" class="mb-4">
        <b-card class="dashboard-filled-line-chart dashboard-link-list" no-body>
          <b-container class="chart-title">
            <div class="float-left float-none-xs">
              <div class="d-inline-block">
                <h5 class="d-inline">
                  {{ $t('pages.vmb.dashboard.profitability') }}
                </h5>
              </div>
            </div>
          </b-container>
          <div class="chart card-body pt-0 pl-3 pr-3 pb-0">
            <scale-loader :loading="!vmbCharts" :color="loader.color"></scale-loader>
            <bar-chart
              v-if="vmbCharts"
              :chart-data="profitabilityChart"
              :options="profitabilityChartOptions"
              :height="250"
            />
          </div>
        </b-card>
      </b-colxx>
      <b-colxx xxs="12">
        <b-card class="mb-4 stats-table" :title="$t('pages.vmb.performance.weekly-data')">
          <scale-loader :loading="!weeklyData" :color="loader.color"></scale-loader>
          <b-table
            v-if="weeklyData"
            responsive
            sticky-header
            :items="weeklyData"
            :fields="weeklyDataFields"
            table-class="text-nowrap"
          ></b-table>
        </b-card>
      </b-colxx>
      <b-colxx xxs="12">
        <b-card class="mb-4 stats-table" :title="$t('pages.vmb.performance.yesterday')">
          <scale-loader :loading="!yesterdayData" :color="loader.color"></scale-loader>
          <b-table
            v-if="yesterdayData"
            responsive
            sticky-header
            :items="yesterdayData"
            :fields="yesterdayDataFields"
            table-class="text-nowrap"
            :tbody-tr-class="rowClass"
          ></b-table>
        </b-card>
      </b-colxx>
    </b-row>
  </div>
</template>

<script>
import BarChart from '@/components/Charts/Bar'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '@/components/Common/VuetablePaginationBootstrap'
import { mapGetters, mapActions } from 'vuex'
import {
  claimsProcessedChartOptions,
  accuracyChartOptions,
  profitabilityChartOptions,
  weeklyDataFields,
  yesterdayDataFields
} from '@/components/Dashboard/config'
import { dataPreloadMixin } from '@/mixins/dataPreloadMixin'
import ScaleLoader from 'vue-spinner/src/ScaleLoader'

export default {
  components: {
    BarChart,
    Vuetable,
    VuetablePaginationBootstrap,
    ScaleLoader
  },
  data() {
    return {
      claimsProcessedChartOptions,
      accuracyChartOptions,
      profitabilityChartOptions,
      weeklyDataFields,
      yesterdayDataFields,
      loader: {
        color: '#2d7281'
      }
    }
  },
  mixins: [dataPreloadMixin],
  methods: {
    ...mapActions(['fetchVmbWeeklyData', 'fetchVmbYesterdayData']),
    async getWeeklyData() {
      await this.fetchVmbWeeklyData()
    },
    async getYesterdayData() {
      let self = this
      await self.fetchVmbYesterdayData(self.openTeamWeek.open_week_id)
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.name.toLowerCase().trim() === 'total') return 'table-warning'
    }
  },
  created() {
    this.getVmbChartData()
    this.getWeeklyData()
    this.getYesterdayData()
  },
  computed: {
    ...mapGetters([
      'claimsProcessedChart',
      'accuracyChart',
      'profitabilityChart',
      'weeklyData',
      'yesterdayData',
      'vmbCharts',
      'currentTeamWeek',
      'currentUser',
      'openTeamWeek'
    ])
  },
  mounted() {
    // this.getCliamsProcessedChart();
    // this.getAccuracyChart();
    // this.getProfitabilityChart();
    // this.getWeeklyData();
    // this.getYesterdayData();
    // this.fetchVmbChartData();
  }
}
</script>

<style lang="scss" scoped>
.link-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1.75rem 1.75rem 0 0;
}

.chart-title {
  padding: 2rem;
}

.v-spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100px;
  text-align: center;
  opacity: 0.8;
}

.stats-table {
  height: 600px;
}

.b-table-sticky-header {
  max-height: 500px;
}

.avatar {
  display: inline-block;
  width: 35px;
  height: 35px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 99em;
  vertical-align: middle;
}
</style>
