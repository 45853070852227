var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _vm.noSuffle != false
        ? _c("b-card-header", { staticClass: "p-0 position-relative" }, [
            _c("div", { staticClass: "position-absolute handle card-icon" }, [
              _c("i", { staticClass: "simple-icon-shuffle" }),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-card-body",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [
          _c("h5", { staticClass: "mb-0 card-title" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "progress-bar-circle" },
            [
              _c(
                "radial-progress-bar",
                {
                  attrs: {
                    diameter: 54,
                    strokeWidth: 2,
                    "completed-steps": _vm.percent,
                    "total-steps": 100,
                  },
                },
                [_vm._v(_vm._s(_vm.percent) + "%")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }