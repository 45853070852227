var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "span",
        { staticClass: "link-icon to-do-items" },
        [
          _c("router-link", { attrs: { tag: "a", to: _vm.detailPath } }, [
            _c("i", { staticClass: "simple-icon-compass" }),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.data
        ? _c(
            "vue-perfect-scrollbar",
            {
              staticClass: "scroll dashboard-list-with-user",
              attrs: {
                settings: { suppressScrollX: true, wheelPropagation: false },
              },
            },
            _vm._l(_vm.data, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "d-flex flex-row mb-3 pb-3 border-bottom",
                  attrs: { data: item },
                },
                [
                  _c("b-form-checkbox", {
                    staticClass: "itemCheck mb-0",
                    attrs: { value: 2, "unchecked-value": 1 },
                    on: {
                      input: function ($event) {
                        return _vm.onChangeItem($event, item.id, _vm.type)
                      },
                    },
                    model: {
                      value: item.status,
                      callback: function ($$v) {
                        _vm.$set(item, "status", $$v)
                      },
                      expression: "item.status",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "pl-2 pr-2 item-container",
                      on: {
                        click: function ($event) {
                          return _vm.onClickItem(item, _vm.header, _vm.type)
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "font-weight-medium mb-0" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text-muted mb-0 text-small" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              item.details +
                                " | " +
                                item.day_name +
                                " | " +
                                item.week_name
                            ) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }