<template>
  <div>
    <span class="link-icon to-do-items">
      <router-link tag="a" :to="detailPath">
        <i class="simple-icon-compass" />
      </router-link>
    </span>
    <vue-perfect-scrollbar
      v-if="data"
      class="scroll dashboard-list-with-user"
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
    >
      <div
        class="d-flex flex-row mb-3 pb-3 border-bottom"
        v-for="item in data"
        :data="item"
        :key="item.id"
      >
        <b-form-checkbox
          class="itemCheck mb-0"
          :value="2"
          :unchecked-value="1"
          v-model="item.status"
          @input="onChangeItem($event, item.id, type)"
        />
        <div class="pl-2 pr-2 item-container" @click="onClickItem(item, header, type)">
          <p class="font-weight-medium mb-0">{{ item.title }}</p>
          <p class="text-muted mb-0 text-small">
            {{ item.details + ' | ' + item.day_name + ' | ' + item.week_name }}
          </p>
        </div>
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import ScaleLoader from 'vue-spinner/src/ScaleLoader'
export default {
  components: { ScaleLoader },
  props: ['header', 'type', 'detailPath', 'data'],
  data() {
    return {
      loader: {
        color: '#2d7281'
      }
    }
  },
  methods: {
    onClickItem(item, header, type) {
      item.type = type
      item.header = header
      this.$emit('clicked', item)
    },
    onChangeItem(event, id, type) {
      let updatedItem = {
        id: id,
        type: type,
        status: event
      }
      this.$emit('changed', updatedItem)
    }
  }
}
</script>
